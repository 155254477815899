import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Button, Card, IconAngleRight, IconShare, IconTrash } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/card",
  "title": "Card",
  "navTitle": "Card"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <Card border heading="Card" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." mdxType="Card" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use Cards sparingly.`}</strong>{` While they can drastically improve service's understandability and readability, overusing them may result in confusing and complex user interfaces.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cards work very well for listing heterogenous content`}</strong>{` such as news items, blog posts, or upcoming events.`}</li>
      <li parentName="ul"><strong parentName="li">{`Cards can also be used to emphasize important content`}</strong>{` such as site CTA (Call To Action).`}</li>
      <li parentName="ul"><strong parentName="li">{`Cards must not be used as interactive elements themselves.`}</strong>{` This means that the whole Card should not be clickable. Elements inside the Card can still be interactable.`}
        <ul parentName="li">
          <li parentName="ul">{`If you need the whole element to be interactable, use `}<InternalLink href="/components/linkbox" mdxType="InternalLink">{`HDS Linkbox`}</InternalLink>{` instead.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Currently, HDS offers base Cards that you can use to build custom Cards for your project's needs. For inspiration and guidelines, you can find custom card examples in `}<ExternalLink href="https://share.goabstract.com/e6aa2ebd-1a49-4cb1-90ef-a44a6486c28b" mdxType="ExternalLink">{`HDS Example Custom Components - Abstract collection`}</ExternalLink>{`.`}</li>
    </ul>
    <h4 {...{
      "id": "should-i-use-a-card-or-a-linkbox",
      "style": {
        "position": "relative"
      }
    }}>{`Should I use a Card or a Linkbox?`}<a parentName="h4" {...{
        "href": "#should-i-use-a-card-or-a-linkbox",
        "aria-label": "should i use a card or a linkbox permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use the Card component when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`the whole Card does not need to be interactable`}</li>
          <li parentName="ul">{`the Card contains multiple interactable elements (e.g. buttons or links)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Use the `}<InternalLink href="/components/linkbox" mdxType="InternalLink">{`Linkbox component`}</InternalLink>{` when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`the whole element needs to be interactable`}</li>
          <li parentName="ul">{`the element does not include multiple interactable elements`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "empty",
      "style": {
        "position": "relative"
      }
    }}>{`Empty`}<a parentName="h4" {...{
        "href": "#empty",
        "aria-label": "empty permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Currently, HDS offers empty base Cards. These and other HDS components can be used to create custom Cards for your project. By default, HDS recommends Cards without border style.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <Card mdxType="Card" />
  <Card border style={{
        marginTop: 'var(--spacing-s)'
      }} mdxType="Card" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-heading-and-body-text",
      "style": {
        "position": "relative"
      }
    }}>{`With heading and body text`}<a parentName="h4" {...{
        "href": "#with-heading-and-body-text",
        "aria-label": "with heading and body text permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Additionally, HDS offers styling for basic heading and body text inside the Card component. These can be used instead of custom elements.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <Card heading="Card" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." mdxType="Card" />
  <Card style={{
        marginTop: 'var(--spacing-s)'
      }} border heading="Card" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." mdxType="Card" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "using-with-other-hds-components",
      "style": {
        "position": "relative"
      }
    }}>{`Using with other HDS components`}<a parentName="h4" {...{
        "href": "#using-with-other-hds-components",
        "aria-label": "using with other hds components permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Custom Cards can be easily built by using other HDS components and typography inside the Card.`}</p>
    <PlaygroundPreview style={{
      backgroundColor: 'var(--color-black-5)'
    }} mdxType="PlaygroundPreview">
  <Card heading="Card" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." mdxType="Card">
    <Button variant="secondary" theme="black" role="link" mdxType="Button">
      Button
    </Button>
  </Card>
  <Card style={{
        marginTop: 'var(--spacing-s)'
      }} border heading="Card" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." mdxType="Card">
    <Button variant="secondary" theme="black" role="link" mdxType="Button">
      Button
    </Button>
  </Card>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      